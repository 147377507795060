<template>
  <div class="body container">
    <Breadcrumb />
    <el-alert show-icon type="warning">
      <template slot="title">
        <span>{{ $t("salary.form.pleaseAdminCheckInformation") }}</span>
      </template>
    </el-alert>
    <el-alert
      v-if="teacherUnpaid"
      style="margin-top: 20px"
      show-icon
      :type="
        progressReportsCompletedCount > progressReportsNeededCount
          ? 'warning'
          : 'error'
      "
    >
      <template slot="title">
        <span
          v-html="
            $t('message.teacherNeedToWriteReports', {
              howManyReportNeedToWrite: progressReportsNeededCount,
              writtenReports: progressReportsCompletedCount
            })
          "
        />
      </template>
    </el-alert>
    <h1 class="text-center mt-5 mb-3">
      {{ $t("salary.createTitle") }}
    </h1>
    <el-row class="rowsGap" :gutter="20" type="flex" align="middle">
      <el-col :span="4">{{ $t("salary.form.teacher") }}</el-col>
      <el-col :span="20">
        <TeacherSelector
          :disabled="$route.name !== 'createSalary'"
          :value="teacherId ? Number(teacherId) : null"
          @change="
            teacher => {
              teacherId = teacher.id;
              setTeacherSalaryForm();
            }
          "
        />
      </el-col>
    </el-row>
    <el-row class="rowsGap" :gutter="20" type="flex" align="middle">
      <el-col :span="4">{{ $t("salary.form.title") }}</el-col>
      <el-col :span="20">
        <el-input v-model="salaryTitle" />
      </el-col>
    </el-row>
    <el-row class="rowsGap">
      <el-table :data="[teacherUnpaid]" style="width: 100%">
        <el-table-column
          :label="$t('column.teacher')"
          prop="user_name"
          width="200"
        />
        <el-table-column>
          <template slot="header">
            <div style="text-align: center">
              {{ $t("column.billingProgress") }}
            </div>
          </template>
          <template slot-scope="scope">
            <StepOfAdmin
              v-if="teacherUnpaid"
              :scope="scope"
              :isViewMode="true"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <hr class="separate-line" />
    <h2>{{ $t("salary.form.salaryStatement") }}</h2>
    <el-row class="rowsGap">
      <el-table
        :data="[...salaryItems, { type: 'addRow' }]"
        style="width: 100%"
        :summary-method="getSalaryItemSummaries"
        show-summary
      >
        <el-table-column :label="$t('column.item')">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.type === 'addRow'"
              @click="addOtherSalaryItem"
              type="primary"
              size="mini"
            >
              {{ $t("salary.form.addItem") }}
            </el-button>
            <el-input
              v-if="scope.row.type === 'editOtherItem'"
              :value="scope.row.statement"
              @input="
                value => {
                  setOtherItem(scope.$index, { statement: value });
                }
              "
            />
            <span v-else>{{ scope.row.statement }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('column.salary')" prop="salary">
          <template slot-scope="scope">
            <InputMoney
              v-if="scope.row.type === 'editOtherItem'"
              :money="scope.row.salary"
              :currency="scope.row.currency"
              @changeMoney="
                price => {
                  setOtherItem(scope.$index, { salary: price });
                }
              "
              @changeCurrency="
                currency => {
                  setOtherItem(scope.$index, { currency });
                }
              "
            />
            <div v-else-if="scope.row.type !== 'addRow'">
              <div>
                {{ addCurrencySymbol(scope.row.salary, scope.row.currency) }}
              </div>
              <div
                v-if="scope.row.currency !== salaryMainCurrency"
                class="originalPrice"
              >
                ≈{{
                  addCurrencySymbol(
                    convertPriceByCurrency(
                      scope.row.salary,
                      scope.row.currency,
                      salaryMainCurrency
                    ),
                    salaryMainCurrency
                  )
                }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('column.action')" width="120">
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.type === 'editOtherItem'"
              :value="true"
              :content="$t('message.remeberSave')"
              placement="top"
              popper-class="tooltipColor"
            >
              <span
                key="check"
                class="action-icon"
                @click="
                  () => {
                    setOtherItem(scope.$index, { type: 'otherItem' });
                  }
                "
              >
                <i class="fas fa-check" />
              </span>
            </el-tooltip>
            <template v-else-if="scope.row.type === 'otherItem'">
              <span
                key="edit"
                class="action-icon"
                @click="
                  () => {
                    setOtherItem(scope.$index, { type: 'editOtherItem' });
                  }
                "
              >
                <i class="fas fa-edit" />
              </span>
              <span
                key="delete"
                class="action-icon warning-icon"
                @click="
                  () => {
                    removeOtherItem(scope.$index);
                  }
                "
              >
                <i class="el-icon-delete" />
              </span>
            </template>
            <template v-else-if="scope.row.type === 'sessionClass'">
              <span
                @click="
                  () => {
                    $message.warning(
                      $t(
                        '如要修改家教課金額請由下方帳單明細直接修改 Lesson Log'
                      )
                    );
                  }
                "
              >
                <i class="fas fa-edit action-icon" />
              </span>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <MultipleCurrencyTotalPrompt v-if="isSalaryItemCurrencyMultiple" />
    </el-row>
    <hr class="separate-line" />
    <el-row class="rowsGap">
      <h2>Lesson Logs</h2>
      <el-table
        :data="teachingLogs"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        :summary-method="getLesssonLogSummaries"
        show-summary
      >
        <el-table-column width="50px">
          <template slot="header">
            <span
              v-if="isSelectAllTeachingLogs"
              key="selectedAll"
              @click="handleSelectedAllTeachingLogs(false)"
            >
              <i class="far fa-check-square" />
            </span>
            <span
              v-else-if="isUnselectAllTeachingLogs"
              key="unselectedAll"
              @click="handleSelectedAllTeachingLogs(true)"
            >
              <i class="far fa-square" />
            </span>
            <span
              v-else
              key="IsNotSelectedAll"
              @click="handleSelectedAllTeachingLogs(true)"
            >
              <i class="far fa-minus-square" />
            </span>
          </template>
          <template slot-scope="scope">
            <span @click="switchSelectType(scope.row.id)">
              <span v-if="scope.row.selected" key="selected">
                <i class="far fa-check-square" />
              </span>
              <span v-else key="unSelected">
                <i class="far fa-square" />
              </span>
            </span>
          </template>
        </el-table-column>
        <el-table-column width="50px">
          <template slot-scope="scope">
            <el-tooltip
              :enterable="false"
              content="Edit"
              placement="top"
              popper-class="tooltipColor"
            >
              <router-link
                :to="{
                  name: 'privateClassesEditLog',
                  params: {
                    privateType: 'private-lessons',
                    id: scope.row.session_classes_id,
                    lessonLogId: scope.row.lesson_logs[0].id
                  }
                }"
              >
                <i class="el-icon-edit-outline action-icon" />
              </router-link>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column :label="$t('column.date')">
          <template slot-scope="scope">
            {{ instant.formatDate(scope.row.started_at) }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('column.teacher')"
          prop="teacher_user_name"
        />
        <el-table-column width="160px" label="是否完成進度報告">
          <template slot-scope="scope">
            <div v-if="scope.row.need_progress_report">
              <span
                key="check"
                v-if="
                  scope.row.is_progress_report_done ===
                    EnumSalaryProgressReportDoneStatuses.finished
                "
                class="action-icon-without-pointer"
              >
                <i class="fas fa-check" />
              </span>
              <span
                key="times"
                v-else-if="
                  scope.row.is_progress_report_done ===
                    EnumSalaryProgressReportDoneStatuses.notFinishedYet
                "
                class="warning-icon-without-pointer"
              >
                <i class="fas fa-times" />
              </span>
              <span
                key="times"
                v-else-if="
                  scope.row.is_progress_report_done ===
                    EnumSalaryProgressReportDoneStatuses.noNeedToWriting
                "
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('column.student')">
          <template slot-scope="scope">
            <span
              :key="lessonLog.id"
              v-for="(lessonLog, index) in scope.row.lesson_logs"
            >
              {{ lessonLog.student_user.name
              }}<span v-if="index !== scope.row.lesson_logs.length - 1">,</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('column.courses')">
          <template slot-scope="scope">
            <router-link
              :to="
                `/private/private-lessons/${scope.row.session_classes_id}/log/${scope.row.lesson_logs[0].id}/student/${scope.row.lesson_logs[0].student_user_id}`
              "
            >
              {{ scope.row.sessionClass.title }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column :label="$t('column.hour')" prop="full_hour" />
        <el-table-column :label="$t('column.salary')">
          <template slot-scope="scope">
            <div>
              {{
                addCurrencySymbol(
                  Number(scope.row.salary) * scope.row.full_hour,
                  scope.row.salary_currency
                )
              }}
            </div>
            <div  class="originalPrice" v-if="scope.row.salary_currency !== teachingLogsMainCurrency">
              ≈
              {{
                addCurrencySymbol(
                  convertPriceByCurrency(
                    Number(scope.row.salary) * scope.row.full_hour,
                    scope.row.salary_currency,
                    teachingLogsMainCurrency
                  ),
                  salaryMainCurrency
                )
              }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <MultipleCurrencyTotalPrompt v-if="isTeachingLogCurrencyMultiple" />
    </el-row>
    <hr class="separate-line" />
    <el-row class="rowsGap">
      <h2>
        {{ $t("salary.form.paymentType") }}
        <el-tooltip
          :enterable="false"
          :content="$t('salary.form.addPaymentMethod')"
          placement="top"
          popper-class="tooltipColor"
        >
          <router-link
            :to="{
              name: 'createPaymentWithTeacher',
              params: { teacherId }
            }"
          >
            <i class="fas fa-plus" />
          </router-link>
        </el-tooltip>
      </h2>
      <el-table
        ref="multipleTable"
        :data="paymentMethods"
        style="width: 100%"
        highlight-current-row
        @current-change="handlePaymentSelectionChange"
      >
        <el-table-column label="" width="40">
          <template slot-scope="scope">
            <el-radio v-model="selectedPaymentMethod" :label="scope.row.id" />
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('salary.form.paymentType')"
          prop="title"
          width="200"
        />
        <el-table-column :label="$t('column.details')">
          <template slot-scope="scope">
            <span :key="index" v-for="(payment, index) in scope.row.payment">
              {{ payment[Object.keys(payment)[0]]
              }}<span v-if="scope.row.payment.length - 1 !== index">,</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Action">
          <template slot-scope="scope">
            <router-link
              class="action-icon"
              :to="{
                name: 'editPayment',
                params: { teacherPaymentId: scope.row.id }
              }"
            >
              <i class="fas fa-edit" />
            </router-link>
            <DeleteIconButton :onDelete="deleteTeacherPayment(scope.row.id)" />
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <hr class="separate-line" />
    <h2>{{ $t("salary.form.note") }}</h2>
    <el-row class="rowsGap" type="flex" justify="center">
      <el-col :span="24">
        <el-input
          :placeholder="$t('salary.form.bothTheAdminAndTheInstructorCanSee')"
          v-model="remark"
          type="textarea"
          rows="5"
        />
      </el-col>
    </el-row>
    <el-row class="rowsGap" type="flex" justify="center">
      <el-col :span="24" style="text-align: center">
        <el-button type="primary" @click="createTeacherPayroll(false, false)">
          暫存薪資單（不會寄信也不會確認）
        </el-button>
        <el-button type="primary" @click="createTeacherPayroll(false, true)">
          {{ $t("salary.form.sendToTeacher") }}
        </el-button>
        <el-button type="primary" @click="createTeacherPayroll(true, false)">
          {{ $t("salary.form.directConfirm") }}
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import moment from "moment";
import { instant, money } from "@ivy-way/material";
import MultipleCurrencyTotalPrompt from "@/components/prompt/MultipleCurrencyTotalPrompt";
import DeleteIconButton from "@/components/button/DeleteIconButton";
import Breadcrumb from "@/components/Breadcrumb";
import { TeacherSelector } from "@/components/selector";
import { InputMoney } from "@/components/input";
import StepOfAdmin from "@/views/salary/StepOfAdmin";
import salaryApi from "@/apis/salary";
import paymentApi from "@/apis/payment";
import moneyMixin from "@/mixins/money";
import { EnumSalaryProgressReportDoneStatuses } from "@/enums";

export default {
  metaInfo() {
    return {
      title: `${this.$t("pageTitle.createSalary")} - Ivy-Way Academy`
    };
  },
  components: {
    MultipleCurrencyTotalPrompt,
    Breadcrumb,
    TeacherSelector,
    StepOfAdmin,
    InputMoney,
    DeleteIconButton
  },
  mixins: [moneyMixin],
  data() {
    return {
      teacherId: null,
      salaryTitle: "",
      salaryItems: [],
      teachingLogs: [],
      paymentMethods: [],
      teacherUnpaid: null,
      selectedPaymentMethod: null,
      progressReportsCompletedCount: 0,
      progressReportsNeededCount: 0,
      remark: ""
    };
  },
  computed: {
    instant() {
      return instant;
    },
    money() {
      return money;
    },
    EnumSalaryProgressReportDoneStatuses() {
      return EnumSalaryProgressReportDoneStatuses;
    },
    isSelectAllTeachingLogs() {
      return this.teachingLogs.every(teachingLog => teachingLog.selected);
    },
    isUnselectAllTeachingLogs() {
      return this.teachingLogs.every(teachingLog => !teachingLog.selected);
    },
    salaryItemCurrencys() {
      return this.getEffectiveSalaryItems().map(salary => salary.currency);
    },
    isSalaryItemCurrencyMultiple() {
      return this.isCurrencyMultiple(this.salaryItemCurrencys);
    },
    salaryMainCurrency() {
      return this.getMainCurrency(this.salaryItemCurrencys);
    },
    teachingLogCurrencys() {
      return this.getSelectedTeachingLogs().map(
        teachingLog => teachingLog.salary_currency
      );
    },
    isTeachingLogCurrencyMultiple() {
      return this.isCurrencyMultiple(this.teachingLogCurrencys);
    },
    teachingLogsMainCurrency() {
      return this.getMainCurrency(this.teachingLogCurrencys);
    }
  },
  async created() {
    await this.initialExchangeRate();
    this.setDefaultTitle();
    this.teacherId = this.$route.params.teacherId;
    if (!this.teacherId) return;
    await this.$store.dispatch("salary/fetchTeachersUnpaids");
    await this.setTeacherSalaryForm();

    switch (this.$route.name) {
      case "createSalaryReset": {
        const {
          payroll_items: payrollItems,
          teaching_logs: teachingLogs
        } = await salaryApi.fetchPayroll(this.$route.params.payrollId);
        teachingLogs.forEach(teachingLog => {
          this.switchSelectType(teachingLog.id);
        });
        this.addOtherItemToSalaryItem(
          payrollItems.filter(({ payable_type }) => payable_type === "")
        );
        break;
      }
      case "createSalaryWithTeacherId": {
        const thisMonth = new moment().format("MM");
        this.teachingLogs.forEach(teachingLog => {
          const teachingLogMonth = new moment(teachingLog.started_at).format(
            "MM"
          );
          if (Number(teachingLogMonth) < Number(thisMonth)) {
            this.switchSelectType(teachingLog.id);
          }
        });
        break;
      }
    }
  },
  methods: {
    initialSalaryItems() {
      this.salaryItems = [
        {
          type: "sessionClass",
          statement: "Private lessons",
          salary: this.getPrivateLessonSalaryTotal(),
          currency: this.teachingLogsMainCurrency
        }
      ];
    },
    async setTeacherSalaryForm() {
      this.teacherUnpaid = this.$store.getters["salary/unpaid"](this.teacherId);
      await this.fetchTeacherPayments();
      const {
        progress_reports_completed_count: progressReportsCompletedCount,
        progress_reports_needed_count: progressReportsNeededCount,
        teaching_logs: teachingLogs,
        session_classes: sessionClasses
      } = await salaryApi.fetchTeacherUnpaid(this.teacherId);
      this.progressReportsCompletedCount = progressReportsCompletedCount;
      this.progressReportsNeededCount = progressReportsNeededCount;
      this.teachingLogs = teachingLogs.map(teachingLog => ({
        selected: false,
        ...teachingLog,
        sessionClass: sessionClasses.find(
          sessionClasse => sessionClasse.id === teachingLog.session_classes_id
        )
      }));
      this.initialSalaryItems();
    },
    updatePrivateLessonSalaryTotal() {
      this.salaryItems[0] = {
        ...this.salaryItems[0],
        salary: this.getPrivateLessonSalaryTotal(),
        currency: this.teachingLogsMainCurrency
      };
      this.salaryItems = [...this.salaryItems];
    },
    addOtherItemToSalaryItem(otherItems) {
      this.salaryItems = [
        ...this.salaryItems,
        ...otherItems.map(otherItem => ({
          currency: otherItem.price_currency,
          salary: otherItem.price,
          statement: otherItem.title,
          type: "otherItem"
        }))
      ];
    },
    handleSelectedAllTeachingLogs(selectedStatue) {
      this.teachingLogs = this.teachingLogs.map(teachingLog => ({
        ...teachingLog,
        selected: selectedStatue
      }));
      this.updatePrivateLessonSalaryTotal();
    },
    addOtherSalaryItem() {
      this.salaryItems = [
        ...this.salaryItems,
        { type: "editOtherItem", statement: "", salary: 0, currency: "TWD" }
      ];
    },
    setOtherItem(index, value) {
      this.salaryItems[index] = {
        ...this.salaryItems[index],
        ...value
      };
      this.salaryItems = [...this.salaryItems];
    },
    removeOtherItem(removeIndex) {
      this.salaryItems = this.salaryItems.filter(
        (salaryItem, index) => index !== removeIndex
      );
    },
    setDefaultTitle() {
      const date = new Date().getDate();
      const month = moment(new Date())
        .subtract(date > 15 ? 0 : 1, "months")
        .format("MMMM");
      this.salaryTitle = `${moment(new Date()).format("YYYY")} ${month} Salary`;
    },
    handlePaymentSelectionChange(selectedPayment) {
      this.selectedPaymentMethod = selectedPayment.id;
    },
    getSelectedTeachingLogs() {
      return this.teachingLogs.filter(teachingLog => teachingLog.selected);
    },
    getPrivateLessonSalaryTotal() {
      const salarys = this.getSelectedTeachingLogs().map(teachingLog => ({
        price: Number(teachingLog.salary) * teachingLog.full_hour,
        currency: teachingLog.salary_currency
      }));
      return this.calculationPriceWithCurrency(salarys);
    },
    switchSelectType(teachingLogId) {
      this.teachingLogs = this.teachingLogs.map(teachingLog => {
        if (teachingLog.id === teachingLogId) {
          return { ...teachingLog, selected: !teachingLog.selected };
        }
        return teachingLog;
      });
      this.updatePrivateLessonSalaryTotal();
    },
    getEffectiveSalaryItems() {
      return this.salaryItems.filter(
        ({ type }) => type === "sessionClass" || type === "otherItem"
      );
    },
    getLesssonLogSummaries({ columns, data: teachingLogs }) {
      const sums = [];
      if (teachingLogs.length === 0) return sums;
      columns.forEach((column, index) => {
        let columnsText = "";
        if (index === 5) columnsText = this.$t("column.total");
        if (index === 6)
          columnsText = this.money.sumUpPrices(
            this.getSelectedTeachingLogs().map(({ full_hour }) => full_hour)
          );

        if (index === 7) {
          columnsText = this.addCurrencySymbol(
            this.getPrivateLessonSalaryTotal(),
            this.teachingLogsMainCurrency
          );
        }

        sums[index] = columnsText;
      });
      return sums;
    },
    getSalaryItemSummaries({ columns, data: salaryItems }) {
      const sums = [];
      if (salaryItems.length === 0) return sums;
      columns.forEach((column, index) => {
        let columnsText = "";
        if (index === 0) columnsText = this.$t("column.total");

        const salarys = this.getEffectiveSalaryItems().map(salaryItem => ({
          price: Number(salaryItem.salary),
          currency: salaryItem.currency
        }));

        if (index === 1) {
          columnsText = this.addCurrencySymbol(
            this.calculationPriceWithCurrency(salarys),
            this.salaryMainCurrency
          );
        }
        sums[index] = columnsText;
      });
      return sums;
    },
    async fetchTeacherPayments() {
      const filterEmpty = paymentInformation =>
        paymentInformation.filter(payment => payment[Object.keys(payment)[0]]);
      const {
        teacher_user_payments: teacherPayments
      } = await paymentApi.fetchTeacherPayments(this.teacherId);
      this.paymentMethods = (teacherPayments || []).map(teacherPayment => ({
        ...teacherPayment,
        payment: filterEmpty(teacherPayment.payment)
      }));
      if (this.paymentMethods.length === 0) return;
      const defaultPaymentMethod = this.paymentMethods.find(
        paymentMethod => paymentMethod.is_default
      );
      if (defaultPaymentMethod) {
        this.selectedPaymentMethod = defaultPaymentMethod.id;
      }
    },
    checkSalaryForm() {
      if (!this.selectedPaymentMethod) {
        this.$message({
          message: this.$t("salary.form.pleaseChoosePayment"),
          type: "warning"
        });
        return false;
      }
      return true;
    },
    async createTeacherPayroll(isDirectCheck, needToSendMail) {
      const notYetFinishSave = this.salaryItems.some(
        salaryItem => salaryItem.type === "editOtherItem"
      );
      if (notYetFinishSave) {
        window.document.documentElement.scrollTop = 0;
        this.$message.error(this.$t("message.pleaseSaveEditingItemFirst"));
        return;
      }
      if (!this.checkSalaryForm()) return;

      const salarys = this.salaryItems
        .filter(({ type }) => type === "sessionClass" || type === "otherItem")
        .map(salaryItem => ({
          price: Number(salaryItem.salary),
          currency: salaryItem.currency
        }));

      const currencys = salarys.map(salary => salary.currency);

      const selectedTeachingLogs = this.teachingLogs.filter(
        teachingLog => teachingLog.selected
      );

      const teachingLogs = [];
      console.log(selectedTeachingLogs);

      selectedTeachingLogs.forEach(selectedTeachingLog => {
        const isExistSessionClass = () =>
          teachingLogs.some(
            item => item.id === selectedTeachingLog.sessionClass.id
          );
        if (isExistSessionClass()) {
          const targetIndex = teachingLogs.findIndex(
            ({ id }) => id === selectedTeachingLog.sessionClass.id
          );

          teachingLogs[targetIndex] = {
            ...teachingLogs[targetIndex],
            price:
              teachingLogs[targetIndex].price +
              (selectedTeachingLog.salary_currency !== this.salaryMainCurrency
                ? this.convertPriceByCurrency(
                  Number(selectedTeachingLog.salary) *
                      selectedTeachingLog.full_hour,
                  selectedTeachingLog.salary_currency,
                  this.salaryMainCurrency
                )
                : Number(selectedTeachingLog.salary) *
                  selectedTeachingLog.full_hour),
            price_currency: this.salaryMainCurrency,
            teaching_logs: [
              ...teachingLogs[targetIndex].teaching_logs,
              selectedTeachingLog.id
            ]
          };
        } else {
          teachingLogs.push({
            type: "session_class",
            id: selectedTeachingLog.sessionClass.id,
            user_id: this.teacherId,
            title: selectedTeachingLog.sessionClass.title,
            price:
              selectedTeachingLog.salary_currency !== this.salaryMainCurrency
                ? this.convertPriceByCurrency(
                  Number(selectedTeachingLog.salary) *
                      selectedTeachingLog.full_hour,
                  selectedTeachingLog.salary_currency,
                  this.salaryMainCurrency
                )
                : Number(selectedTeachingLog.salary) *
                  selectedTeachingLog.full_hour,
            price_currency: this.salaryMainCurrency,
            teaching_logs: [selectedTeachingLog.id]
          });
        }
      });

      const otherItems = this.salaryItems
        .filter(salaryItem => salaryItem.type === "otherItem")
        .map((otherItem, index) => ({
          user_id: this.teacherId,
          title: otherItem.statement,
          price: Number(otherItem.salary),
          price_currency: otherItem.currency
        }));
      const payroll = {
        title: this.salaryTitle,
        user_id: this.teacherId,
        total_price: this.calculationPriceWithCurrency(salarys),
        price_currency: this.getMainCurrency(currencys),
        teacher_user_payment_id: this.selectedPaymentMethod,
        remark: this.remark,
        items: [...teachingLogs, ...otherItems]
      };
      try {
        let payrollId = null;
        if (this.$route.name === "createSalaryReset") {
          const { payroll: newPayroll } = await salaryApi.resetTeacherPayroll(
            this.$route.params.payrollId,
            payroll
          );
          payrollId = newPayroll.id;
        } else {
          const newPayroll = await salaryApi.createTeacherPayroll(payroll);
          payrollId = newPayroll.id;
        }
        this.$message({
          showClose: true,
          message: this.$t("message.create_success"),
          type: "success"
        });

        if (isDirectCheck) {
          await this.checkTeacherPayroll(payrollId);
        }

        if (needToSendMail) {
          await salaryApi.sendSalaryMail(payrollId);
        }

        this.$router.push({ name: "Salary" });
      } catch (e) {
        this.$message({
          showClose: true,
          message: this.$t("message.something_went_wrong"),
          type: "error"
        });
      }
    },
    async checkTeacherPayroll(salaryId) {
      if (salaryId) {
        await salaryApi.markCheckoedPayroll(salaryId);
      }
    },
    deleteTeacherPayment(teacherPaymentId) {
      return async () => {
        await paymentApi.deleteTeacherPayments(teacherPaymentId);
        this.fetchTeacherPayments();
      };
    },
    tableRowClassName({ row }) {
      if (row.selected) {
        return "";
      } else {
        return "unSelected";
      }
    }
  }
};
</script>

<style scoped>
::v-deep .is-icon {
  width: 140px;
}

::v-deep .el-step__main {
  text-align: center;
  width: 140px;
}

.rowsGap {
  margin: 20px 0px;
}
.table th,
.table td {
  padding: 4px;
}

h2 {
  color: #43a06b;
}

.originalPrice {
  margin-left: 24px;
  color: #999999;
}

.el-table__row:hover .el-steps ::v-deep .is-icon {
  background: rgb(245, 247, 250);
}

::v-deep .unSelected {
  opacity: 0.5;
}
</style>
