var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body container"},[_c('Breadcrumb'),_c('el-alert',{attrs:{"show-icon":"","type":"warning"}},[_c('template',{slot:"title"},[_c('span',[_vm._v(_vm._s(_vm.$t("salary.form.pleaseAdminCheckInformation")))])])],2),(_vm.teacherUnpaid)?_c('el-alert',{staticStyle:{"margin-top":"20px"},attrs:{"show-icon":"","type":_vm.progressReportsCompletedCount > _vm.progressReportsNeededCount
        ? 'warning'
        : 'error'}},[_c('template',{slot:"title"},[_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.$t('message.teacherNeedToWriteReports', {
            howManyReportNeedToWrite: _vm.progressReportsNeededCount,
            writtenReports: _vm.progressReportsCompletedCount
          })
        )}})])],2):_vm._e(),_c('h1',{staticClass:"text-center mt-5 mb-3"},[_vm._v(" "+_vm._s(_vm.$t("salary.createTitle"))+" ")]),_c('el-row',{staticClass:"rowsGap",attrs:{"gutter":20,"type":"flex","align":"middle"}},[_c('el-col',{attrs:{"span":4}},[_vm._v(_vm._s(_vm.$t("salary.form.teacher")))]),_c('el-col',{attrs:{"span":20}},[_c('TeacherSelector',{attrs:{"disabled":_vm.$route.name !== 'createSalary',"value":_vm.teacherId ? Number(_vm.teacherId) : null},on:{"change":teacher => {
            _vm.teacherId = teacher.id;
            _vm.setTeacherSalaryForm();
          }}})],1)],1),_c('el-row',{staticClass:"rowsGap",attrs:{"gutter":20,"type":"flex","align":"middle"}},[_c('el-col',{attrs:{"span":4}},[_vm._v(_vm._s(_vm.$t("salary.form.title")))]),_c('el-col',{attrs:{"span":20}},[_c('el-input',{model:{value:(_vm.salaryTitle),callback:function ($$v) {_vm.salaryTitle=$$v},expression:"salaryTitle"}})],1)],1),_c('el-row',{staticClass:"rowsGap"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":[_vm.teacherUnpaid]}},[_c('el-table-column',{attrs:{"label":_vm.$t('column.teacher'),"prop":"user_name","width":"200"}}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.teacherUnpaid)?_c('StepOfAdmin',{attrs:{"scope":scope,"isViewMode":true}}):_vm._e()]}}])},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("column.billingProgress"))+" ")])])],2)],1)],1),_c('hr',{staticClass:"separate-line"}),_c('h2',[_vm._v(_vm._s(_vm.$t("salary.form.salaryStatement")))]),_c('el-row',{staticClass:"rowsGap"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":[..._vm.salaryItems, { type: 'addRow' }],"summary-method":_vm.getSalaryItemSummaries,"show-summary":""}},[_c('el-table-column',{attrs:{"label":_vm.$t('column.item')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type === 'addRow')?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.addOtherSalaryItem}},[_vm._v(" "+_vm._s(_vm.$t("salary.form.addItem"))+" ")]):_vm._e(),(scope.row.type === 'editOtherItem')?_c('el-input',{attrs:{"value":scope.row.statement},on:{"input":value => {
                _vm.setOtherItem(scope.$index, { statement: value });
              }}}):_c('span',[_vm._v(_vm._s(scope.row.statement))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('column.salary'),"prop":"salary"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type === 'editOtherItem')?_c('InputMoney',{attrs:{"money":scope.row.salary,"currency":scope.row.currency},on:{"changeMoney":price => {
                _vm.setOtherItem(scope.$index, { salary: price });
              },"changeCurrency":currency => {
                _vm.setOtherItem(scope.$index, { currency });
              }}}):(scope.row.type !== 'addRow')?_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.addCurrencySymbol(scope.row.salary, scope.row.currency))+" ")]),(scope.row.currency !== _vm.salaryMainCurrency)?_c('div',{staticClass:"originalPrice"},[_vm._v(" ≈"+_vm._s(_vm.addCurrencySymbol( _vm.convertPriceByCurrency( scope.row.salary, scope.row.currency, _vm.salaryMainCurrency ), _vm.salaryMainCurrency ))+" ")]):_vm._e()]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('column.action'),"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type === 'editOtherItem')?_c('el-tooltip',{attrs:{"value":true,"content":_vm.$t('message.remeberSave'),"placement":"top","popper-class":"tooltipColor"}},[_c('span',{key:"check",staticClass:"action-icon",on:{"click":() => {
                  _vm.setOtherItem(scope.$index, { type: 'otherItem' });
                }}},[_c('i',{staticClass:"fas fa-check"})])]):(scope.row.type === 'otherItem')?[_c('span',{key:"edit",staticClass:"action-icon",on:{"click":() => {
                  _vm.setOtherItem(scope.$index, { type: 'editOtherItem' });
                }}},[_c('i',{staticClass:"fas fa-edit"})]),_c('span',{key:"delete",staticClass:"action-icon warning-icon",on:{"click":() => {
                  _vm.removeOtherItem(scope.$index);
                }}},[_c('i',{staticClass:"el-icon-delete"})])]:(scope.row.type === 'sessionClass')?[_c('span',{on:{"click":() => {
                  _vm.$message.warning(
                    _vm.$t(
                      '如要修改家教課金額請由下方帳單明細直接修改 Lesson Log'
                    )
                  );
                }}},[_c('i',{staticClass:"fas fa-edit action-icon"})])]:_vm._e()]}}])})],1),(_vm.isSalaryItemCurrencyMultiple)?_c('MultipleCurrencyTotalPrompt'):_vm._e()],1),_c('hr',{staticClass:"separate-line"}),_c('el-row',{staticClass:"rowsGap"},[_c('h2',[_vm._v("Lesson Logs")]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.teachingLogs,"row-class-name":_vm.tableRowClassName,"summary-method":_vm.getLesssonLogSummaries,"show-summary":""}},[_c('el-table-column',{attrs:{"width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{on:{"click":function($event){return _vm.switchSelectType(scope.row.id)}}},[(scope.row.selected)?_c('span',{key:"selected"},[_c('i',{staticClass:"far fa-check-square"})]):_c('span',{key:"unSelected"},[_c('i',{staticClass:"far fa-square"})])])]}}])},[_c('template',{slot:"header"},[(_vm.isSelectAllTeachingLogs)?_c('span',{key:"selectedAll",on:{"click":function($event){return _vm.handleSelectedAllTeachingLogs(false)}}},[_c('i',{staticClass:"far fa-check-square"})]):(_vm.isUnselectAllTeachingLogs)?_c('span',{key:"unselectedAll",on:{"click":function($event){return _vm.handleSelectedAllTeachingLogs(true)}}},[_c('i',{staticClass:"far fa-square"})]):_c('span',{key:"IsNotSelectedAll",on:{"click":function($event){return _vm.handleSelectedAllTeachingLogs(true)}}},[_c('i',{staticClass:"far fa-minus-square"})])])],2),_c('el-table-column',{attrs:{"width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{attrs:{"enterable":false,"content":"Edit","placement":"top","popper-class":"tooltipColor"}},[_c('router-link',{attrs:{"to":{
                name: 'privateClassesEditLog',
                params: {
                  privateType: 'private-lessons',
                  id: scope.row.session_classes_id,
                  lessonLogId: scope.row.lesson_logs[0].id
                }
              }}},[_c('i',{staticClass:"el-icon-edit-outline action-icon"})])],1)]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('column.date')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.instant.formatDate(scope.row.started_at))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('column.teacher'),"prop":"teacher_user_name"}}),_c('el-table-column',{attrs:{"width":"160px","label":"是否完成進度報告"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.need_progress_report)?_c('div',[(
                scope.row.is_progress_report_done ===
                  _vm.EnumSalaryProgressReportDoneStatuses.finished
              )?_c('span',{key:"check",staticClass:"action-icon-without-pointer"},[_c('i',{staticClass:"fas fa-check"})]):(
                scope.row.is_progress_report_done ===
                  _vm.EnumSalaryProgressReportDoneStatuses.notFinishedYet
              )?_c('span',{key:"times",staticClass:"warning-icon-without-pointer"},[_c('i',{staticClass:"fas fa-times"})]):(
                scope.row.is_progress_report_done ===
                  _vm.EnumSalaryProgressReportDoneStatuses.noNeedToWriting
              )?_c('span',{key:"times"}):_vm._e()]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('column.student')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((scope.row.lesson_logs),function(lessonLog,index){return _c('span',{key:lessonLog.id},[_vm._v(" "+_vm._s(lessonLog.student_user.name)),(index !== scope.row.lesson_logs.length - 1)?_c('span',[_vm._v(",")]):_vm._e()])})}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('column.courses')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":`/private/private-lessons/${scope.row.session_classes_id}/log/${scope.row.lesson_logs[0].id}/student/${scope.row.lesson_logs[0].student_user_id}`}},[_vm._v(" "+_vm._s(scope.row.sessionClass.title)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('column.hour'),"prop":"full_hour"}}),_c('el-table-column',{attrs:{"label":_vm.$t('column.salary')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(_vm.addCurrencySymbol( Number(scope.row.salary) * scope.row.full_hour, scope.row.salary_currency ))+" ")]),(scope.row.salary_currency !== _vm.teachingLogsMainCurrency)?_c('div',{staticClass:"originalPrice"},[_vm._v(" ≈ "+_vm._s(_vm.addCurrencySymbol( _vm.convertPriceByCurrency( Number(scope.row.salary) * scope.row.full_hour, scope.row.salary_currency, _vm.teachingLogsMainCurrency ), _vm.salaryMainCurrency ))+" ")]):_vm._e()]}}])})],1),(_vm.isTeachingLogCurrencyMultiple)?_c('MultipleCurrencyTotalPrompt'):_vm._e()],1),_c('hr',{staticClass:"separate-line"}),_c('el-row',{staticClass:"rowsGap"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t("salary.form.paymentType"))+" "),_c('el-tooltip',{attrs:{"enterable":false,"content":_vm.$t('salary.form.addPaymentMethod'),"placement":"top","popper-class":"tooltipColor"}},[_c('router-link',{attrs:{"to":{
            name: 'createPaymentWithTeacher',
            params: { teacherId: _vm.teacherId }
          }}},[_c('i',{staticClass:"fas fa-plus"})])],1)],1),_c('el-table',{ref:"multipleTable",staticStyle:{"width":"100%"},attrs:{"data":_vm.paymentMethods,"highlight-current-row":""},on:{"current-change":_vm.handlePaymentSelectionChange}},[_c('el-table-column',{attrs:{"label":"","width":"40"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-radio',{attrs:{"label":scope.row.id},model:{value:(_vm.selectedPaymentMethod),callback:function ($$v) {_vm.selectedPaymentMethod=$$v},expression:"selectedPaymentMethod"}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('salary.form.paymentType'),"prop":"title","width":"200"}}),_c('el-table-column',{attrs:{"label":_vm.$t('column.details')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((scope.row.payment),function(payment,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(payment[Object.keys(payment)[0]])),(scope.row.payment.length - 1 !== index)?_c('span',[_vm._v(",")]):_vm._e()])})}}])}),_c('el-table-column',{attrs:{"label":"Action"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{staticClass:"action-icon",attrs:{"to":{
              name: 'editPayment',
              params: { teacherPaymentId: scope.row.id }
            }}},[_c('i',{staticClass:"fas fa-edit"})]),_c('DeleteIconButton',{attrs:{"onDelete":_vm.deleteTeacherPayment(scope.row.id)}})]}}])})],1)],1),_c('hr',{staticClass:"separate-line"}),_c('h2',[_vm._v(_vm._s(_vm.$t("salary.form.note")))]),_c('el-row',{staticClass:"rowsGap",attrs:{"type":"flex","justify":"center"}},[_c('el-col',{attrs:{"span":24}},[_c('el-input',{attrs:{"placeholder":_vm.$t('salary.form.bothTheAdminAndTheInstructorCanSee'),"type":"textarea","rows":"5"},model:{value:(_vm.remark),callback:function ($$v) {_vm.remark=$$v},expression:"remark"}})],1)],1),_c('el-row',{staticClass:"rowsGap",attrs:{"type":"flex","justify":"center"}},[_c('el-col',{staticStyle:{"text-align":"center"},attrs:{"span":24}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.createTeacherPayroll(false, false)}}},[_vm._v(" 暫存薪資單（不會寄信也不會確認） ")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.createTeacherPayroll(false, true)}}},[_vm._v(" "+_vm._s(_vm.$t("salary.form.sendToTeacher"))+" ")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.createTeacherPayroll(true, false)}}},[_vm._v(" "+_vm._s(_vm.$t("salary.form.directConfirm"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }